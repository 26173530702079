import { render, staticRenderFns } from "./subject.vue?vue&type=template&id=104f2920&scoped=true&"
import script from "./subject.vue?vue&type=script&lang=js&"
export * from "./subject.vue?vue&type=script&lang=js&"
import style0 from "./subject.vue?vue&type=style&index=0&id=104f2920&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104f2920",
  null
  
)

export default component.exports